<template>
  <div id="mapa-brasil" />
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import geojson from '@/components/general/graficos/data/brasil.json';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [browserPrintMixins],
  data() {
    return {
      map: null
    };
  },
  mounted() {
    this.configuraMapa(3);
    this.adicionaMapaBrasil();
    this.map.setView([-13.649319, -56.1236542]);
  },
  methods: {
    configuraMapa(zoom) {
      this.map = L.map('mapa-brasil', {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);

      this.createBrowserPrintControl(L, 'Mapa do Brasil').addTo(this.map);
    },
    adicionaMapaBrasil() {
      L.geoJSON(geojson, {
        style: function () {
          return {
            color: '#f66448',
            weight: 1
          };
        }
      }).addTo(this.map);
    }
  }
};
</script>

<style scoped>
#mapa-brasil {
  min-height: 300px;
  z-index: 0;
}
</style>
